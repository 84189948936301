<template>
  <div class="nav-link">
    <Button
      :class="activeNav === href && 'is-active-nav'"
      text
      :rightIcon="expandable ? 'mdi-chevron-up' : null"
      :flipRightIcon="expanded"
      :to="href"
      :href="hash"
      @click="handleClick"
      v-if="navExpanded"
    >
      {{ name }}
    </Button>
    <Button
      :class="activeNav === href && 'is-active-nav'"
      text
      class="collapsed-button"
      :to="href"
      :href="hash"
      v-if="!navExpanded"
    >
      <v-icon v-if="icon">{{ icon }}</v-icon
      >{{ name }}
    </Button>
    <div
      class="subnav-links"
      v-for="sublink in links"
      :key="sublink.name"
      v-show="expanded || !navExpanded"
    >
      <Button
        :class="activeNav === sublink.href && 'is-active-nav'"
        text
        class="subnav"
        :to="sublink.href"
        :href="sublink.hash"
        v-if="navExpanded"
        >{{ sublink.name }}</Button
      >
      <Button
        :class="activeNav === sublink.href && 'is-active-nav'"
        text
        class="collapsed-button"
        :to="sublink.href"
        :href="sublink.hash"
        v-if="!navExpanded"
      >
        <v-icon v-if="sublink.icon">{{ sublink.icon }}</v-icon
        >{{ sublink.name }}</Button
      >
    </div>
  </div>
</template>

<script>
/**
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import Button from "@/qux-common/qux22/components/molecules/button";

export default {
  name: "NavigationLink",
  components: { Button },
  data() {
    return {
      expanded: false,
    };
  },
  props: {
    activeNav: {
      type: String,
    },
    name: {
      type: String,
    },
    links: {
      type: Array,
    },
    href: {
      type: String,
    },
    hash: {
      type: String,
    },
    icon: {
      type: String,
    },
    navExpanded: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick() {
      this.expandable ? this.toggleExpanded() : null;
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
  },
  computed: {
    expandable() {
      return !!this.links;
    },
  },
};
</script>

<style lang="scss" scoped>
.button.v-btn:not(.v-btn--round).v-btn--text.collapsed-button {
  padding: 8px 4px;
  min-height: 52px;
  &::v-deep {
    .v-btn__content {
      justify-content: center;
      .label {
        display: flex;
        flex-direction: column;
        color: #000;
        // caption styles
        font-size: 0.75rem;
        font-weight: 350;
        line-height: 1.5;
        .v-icon {
          color: $qvo-color-grey-500;
        }
      }
    }
  }
}
</style>