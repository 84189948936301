<template>
  <globalNavigation :navData="navData" :navExpanded="navExpanded" />
</template>

<script>
/**
 * @fileoverview Side Navigation
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import { mapGetters } from "vuex";

import globalNavigation from "@/qux-common/qux22/components/organisms/global-navigation";

export default {
  components: {
    globalNavigation,
  },
  computed: {
    navData() {
      const navData = [
        {
          name: "",
          links: [
            {
              name: "Home",
              href: "/",
              icon: '$home',
            },
            {
              name: "Tasks",
              href: "/tasks",
              icon: '$queue',
            },
          ],
        },
      ];
      return navData;
    },
    ...mapGetters({
      navExpanded: "getNavExpanded",
    }),
  },
};
</script>

<style lang="scss" scoped>
.navigation-wrapper {
  grid-area: sidenav;
  &::v-deep {
    .nav-group .nav-link .v-btn--active.is-active-nav.v-btn--text {
      background-color: $qvo-color-blue-400;
      .v-btn__content .label {
        color: $qvo-color-grey-000;
      }
    }
  }
}
</style>